import linkedin from "./linkedin.png";
import message from "./message.png";
import BetaCodeLogo from "./BetaCode.png";
import down_arrow from "./down-arrow.png";
export default {
  linkedin,
  message,
  BetaCodeLogo,
  down_arrow,
};
